/**상담사 신규등록 팝업*/
<template>
    <div class="jh-dialog-wrap">
        <div class="jh-dialog-header">
            <h1>{{ param.headerTitle }}</h1>
            <div class="is-right">
                <v-btn class="jh-btn is-icon" title="닫기" @click="$emit('hide')"><i class="jh-icon-close-lg is-white"></i></v-btn>
            </div>
        </div>
        <div class="jh-dialog-body">
            <template v-if="p01">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="100px">
                            <col>
                            <col width="100px">
                            <col>                            
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label class="is-required">ID</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" @change="chkDupleUserID" :rules="validateRules.userId" v-model="USER_ID"></v-text-field>
                                </td>
                                <th><label class="is-required">권한그룹명</label></th>
                                <td>
                                    <v-select class="jh-form is-md" :items="dropGroupItems" item-text="NM" item-value="CD" placeholder="선택하세요" :rules="validateRules.atrtGroupCd" v-model="ATRT_GROUP_CD"></v-select>
                                </td>                   
                            </tr>
                            <tr>
                                <th><label class="is-required">회원명</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" :rules="validateRules.userNm" v-model="USER_NM"></v-text-field>
                                </td>
                                <th><label class="is-required">주민번호</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" :rules="validateRules.userSsn" v-model="USER_SSN"></v-text-field>
                                </td>                                
                            </tr>
                            <tr>
                                <th><label class="is-required">연락처1</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" :rules="validateRules.hpNo" v-model="HP_NO"></v-text-field>
                                </td>
                                <th><label>연락처2</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" v-model="IPT_NO"></v-text-field>
                                </td>                                
                            </tr>
                            <tr>
                                <th><label class="is-required">구분</label></th>
                                <td>
                                    <v-select class="jh-form is-md" :items="dropYn" item-text="title" item-value="value" placeholder="선택하세요" v-model="BRCH_CD"></v-select>
                                </td>
                                <th><label>소속사</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" v-model="CEN_TYPE_CD"></v-text-field>
                                </td>                      
                            </tr>              

                            <tr>
                                <th><label>지사</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" v-model="JISA"></v-text-field>
                                </td>     
                                <th><label>지점</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" v-model="JISUM"></v-text-field>
                                </td>                      
                            </tr>                                                
                            <tr>
                                <th><label>은행</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" v-model="USER_BK"></v-text-field>
                                </td>
                                <th><label>계좌번호</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" v-model="USER_ACT"></v-text-field>
                                </td>                                
                            </tr>                                                        
                            <tr>
                                <th rowspan="3" style="height: 135px;"><label>고객의견</label></th>
                                <td rowspan="3" style="height: 135px;">
                                    <v-textarea class="jh-form" style="height: 135px;" name="name" value="" id="id" v-model="USER_OP"></v-textarea>
                                </td> 
                                <th rowspan="3" style="height: 135px;"><label>메모</label></th>
                                <td rowspan="3" style="height: 135px;">
                                    <v-textarea class="jh-form" style="height: 135px;" name="name" value="" id="id" v-model="MEMO"></v-textarea>
                                    <!-- v-textarea class="jh-form" height="150px" -->
                                </td>                                 
                            </tr>
                        </tbody>
                    </table>
                </v-form>
            </template>
            <template v-if="p02">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="100px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label class="is-required">사번</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id"  v-model="detailList.USER_ID" disabled></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label class="is-required">상담사명</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" name="name" value="" id="id" :rules="validateRules.userNm" v-model="detailList.USER_NM"></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label class="is-required">사용여부</label></th>
                                <td>
                                    <v-select class="jh-form is-md" :items="dropYn" item-text="title" item-value="value" placeholder="선택하세요" v-model="detailList.USE_YN"></v-select>
                                </td>
                            </tr>
                            <tr>
                                <th><label class="is-required">권한그룹명</label></th>
                                <td>
                                    <v-select class="jh-form is-md" :items="dropGroupItems" item-text="NM" item-value="CD" placeholder="선택하세요" :rules="validateRules.atrtGroupCd" v-model="detailList.ATRT_GROUP_ID"></v-select>
                                </td>
                            </tr>
                            <tr>
                                <th><label class="is-required">채팅제한수</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md" type="number" name="MAX_CHAT_AGENT" id="MAX_CHAT_AGENT" min="0" max="9999" :rules="validateRules.maxChatAgent"  v-model="detailList.MAX_CHAT_AGENT" required></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label>최근접속IP</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id" v-model="detailList.CNNCT_IP" disabled></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label>등록자</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id" v-model="detailList.REGR_NM" disabled></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label>등록일시</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id" v-model="detailList.VIEW_REG_DTTM" disabled></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label>수정자</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id" v-model="detailList.AMDR_NM" disabled></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label>수정일시</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id" v-model="detailList.VIEW_UPD_DTTM" disabled></v-text-field>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </v-form>
            </template>
            <template v-if="p03">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <table class="jh-tbl-detail">
                        <colgroup>
                            <col width="80px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th><label>사번</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id"  v-model="detailList.USER_ID" disabled></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label>상담사명</label></th>
                                <td>
                                    <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id" v-model="detailList.USER_NM" disabled></v-text-field>
                                </td>
                            </tr>
                            <tr>
                                <th><label class="is-required">부서</label></th>
                                <td>
                                    <v-select class="jh-form is-md" :items="dropA" item-text="CD_NM" item-value="CD" placeholder="선택하세요" v-model="USER_ATTR_A"></v-select>
                                </td>
                            </tr>
                            <tr>
                                <th><label class="is-required">파트</label></th>
                                <td>
                                    <v-select class="jh-form is-md" :items="dropB" item-text="CD_NM" item-value="CD" placeholder="선택하세요" v-model="USER_ATTR_B"></v-select>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>
                                    <v-select class="jh-form is-md" :items="dropC" item-text="CD_NM" item-value="CD" placeholder="선택하세요" v-model="paramData.USER_ATTR_C"></v-select>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>
                </v-form>
            </template>
            <template v-if="p04">
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label class="is-required">소속그룹코드</label></th>
                            <td>
                                <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id"  v-model="paramData.ATTR_CD" disabled></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">소속코드</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" name="name" value="" id="id" :rules="validateRules.attrDivCd" v-model="ATTR_DIV_CD" required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">소속코드명</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" name="name" value="" id="id" :rules="validateRules.attrDivNm" v-model="ATTR_DIV_NM" required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">사용여부</label></th>
                            <td>
                                <v-select class="jh-form is-md" :items="dropYn" item-text="title" item-value="value" placeholder="선택하세요" :rules="validateRules.useYn" v-model="USE_YN" required></v-select>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">정렬순서</label></th>
                            <td>
                                <v-text-field type="number" class="jh-form is-md" name="SORT_ORD" id="sortOrd" min="0" max="9999" :rules="validateRules.sortOrd" v-model.number.lazy="SORT_ORD" required></v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-if="p05">
                <table class="jh-tbl-detail">
                    <colgroup>
                        <col width="100px">
                        <col>
                    </colgroup>
                    <tbody>
                        <tr>
                            <th><label class="is-required">소속그룹코드</label></th>
                            <td>
                                <v-text-field class="jh-form is-md is-trns" name="name" value="" id="id"  v-model="paramData.ATTR_CD" disabled></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">소속코드</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" name="name" v-model="paramData.ATTR_DIV_CD" disabled></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">소속코드명</label></th>
                            <td>
                                <v-text-field class="jh-form is-md" name="name" value="" id="id" :rules="validateRules.attrDivNm" v-model="paramData.ATTR_DIV_NM" required></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">사용여부</label></th>
                            <td>
                                <v-select class="jh-form is-md" :items="dropYn" item-text="title" item-value="value" placeholder="선택하세요" :rules="validateRules.useYn" v-model="paramData.USE_YN" required></v-select>
                            </td>
                        </tr>
                        <tr>
                            <th><label class="is-required">정렬순서</label></th>
                            <td>
                                <v-text-field type="number" class="jh-form is-md" name="SORT_ORD" id="sortOrd" min="0" max="9999" :rules="validateRules.sortOrd" v-model.number.lazy="paramData.SORT_ORD" required></v-text-field>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <div class="jh-info-box is-mt-10" v-if="footerYn">
                <div class="jh-info-txt is-fill is-red" v-if="param.type === 'I'">상담사 초기 비밀번호는 <em>"1111"</em> 입니다.</div>
                <div class="jh-info-txt is-fill is-red">기간계 인사정보 통합정책으로 신규등록은 자제하여 주시기 바랍니다.</div>
            </div>
    </div>
    <div class="jh-dialog-footer">
        <template v-if="p02">
            <v-btn class="jh-btn is-md is-light" v-if="this.mixin_set_btn(this.$options.name, 'btnInitPW')" id="btnReset" @click="resetBtn">비밀번호 초기화</v-btn>
            <v-btn class="jh-btn is-md is-del" v-if="this.mixin_set_btn(this.$options.name, 'btnUnlock')" id="btnUnLock" @click="unlockBtn">잠금해제</v-btn>
        </template>
        <v-btn class="jh-btn is-md is-main"  id="btnSave01" @click="saveBtn">저장</v-btn>
    </div>
</div>
</template>

<script>

import api from "@/store/apiUtil";
import {mixin} from "../../mixin/mixin";
import gibberish from "../../mixin/gibberish-aes_mixin";

export default {
  name  : "MENU_E040106P01", //name은 'MENU_' + 파일명 조합
  mixins: [mixin, gibberish],
  props : {
    param      : {
      type: Object,
    },
    p01        : {
      type   : Boolean,
      default: false,
    },
    p02        : {
      type   : Boolean,
      default: false,
    },
    p03        : {
      type   : Boolean,
      default: false,
    },
    p04        : {
      type   : Boolean,
      default: false,
    },
    p05        : {
      type   : Boolean,
      default: false,
    },
    headerTitle: {
      type   : String,
      default: "제목",
    },
  },
  data() {
    return {

      footerHideTitle: "닫기",
      dropItems      : [],
      // dropYn         : [
      //   {title: "사용", value: "Y"},
      //   {title: "미사용", value: "N"},
      // ],
        dropYn:[
          { title: "설계사", value:"S" },
          { title: "딜러", value:"D" },
          { title: "일반", value:"N" },
        ],      
      dropGroupItems : [],
      dropA          : [
        {CD: "", CD_NM: "선택"}
      ],
      dropB          : [
        {CD: "", CD_NM: "선택"}
      ],
      dropC          : [
        {CD: "", CD_NM: "선택"}
      ],
      dropD          : [
        {CD: "", CD_NM: "선택"}
      ],
      detailList     : [],
      groupAddList   : [],

      //v-model
      ASP_NEWCUST_KEY: '001',
      USER_ATTR_A    : '',
      USER_ATTR_B    : '',
      USER_ATTR_C    : '',
      USER_ATTR_D    : '',
      PROC_ID        : '', //처리자ID
      REGR_ID        : '', //등록자ID
      AMDR_ID        : '', //수정자ID
      USER_ID        : '',
      USER_NM        : '',
      USER_NICK      : '',
      USE_YN         : 'Y',
      ATRT_GROUP_CD  : '',
      ATTR_DIV_CD    : '',
      ATTR_DIV_NM    : '',
      SORT_ORD       : 0,
      footerYn       : false,

      USER_SSN : '',
      HP_NO: '',
      IPT_NO: '',
      CEN_TYPE_CD: '',
      JISA: '',
      JISUM: '',
      USER_BK: '',
      USER_ACT: '',
      USER_OP: '',
      MEMO: '',
      BRCH_CD: '',
      //메세지
      msgList: {
        chkCustLength: '회사명은 3개 이하로 선택해주세요',

      },
      //valid관련 데이터
      valid        : true,
      validateRules: {
        // maxChatAgent : [
        //   v => /[0-9]/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
        //   v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
        // ],
        // sortOrd : [
        //   v => /[0-9]/.test(v) || '정렬번호는 숫자만 입력 가능합니다.',
        //   v => (v >= 0 && v < 10000 ) || '0 ~ 9999 이내로 입력해 주세요.',
        // ],
        // aspNewCustKey: [
        //   v => !!v || '회사선택은 필수입니다.',
        //   // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        // ],
        userId       : [
          v => !!v || '사번는 필수입니다.',
          // v => /^[a-zA-Z0-9]*$/.test(v)  || '영문, 숫자만 사용 가능합니다.',
        ],
        userSsn     : [
          v => !!v || '닉네임은 필수입니다.',
        ],
        userNm       : [
          v => !!v || '상담사명은 필수입니다.',
        ],
        hpNo       : [
          v => !!v || '상담사명은 필수입니다.',
        ],        
        atrtGroupCd  : [
          v => !!v || '권한그룹선택은 필수입니다.',
        ],
        BRCH_CD        : [
          v => !!v || '사용여부는 필수입니다.',
        ],

      },

      USER_ATTR_A: '',
      USER_ATTR_B: '',
    }
  },
  created() {

  },
  mounted() {
    this.init();
    this.getList();
  },
  computed: {
    initHeaders() {
      return {
        SERVICE: 'setting.agent.manage',
        METHOD : "",
        TYPE   : 'BIZ_SERVICE',
      };
    },

    paramData() {
      return {
        USER_ID         : this.param.data.USER_ID,
        ASP_NEWCUST_KEY : "001",
        LAST_LOGIN_DT   : this.param.data.LAST_LOGIN_DT,
        USER_ATTR_A     : this.param.data.USER_ATTR_A,
        USER_ATTR_B     : this.param.data.USER_ATTR_B,
        USER_ATTR_C     : this.param.data.USER_ATTR_C,
        USER_ATTR_D     : this.param.data.USER_ATTR_D,
        dropA           : [],
        dropB           : [],
        dropC           : this.param.dropC,
        dropD           : this.param.dropD,
        ATTR_CD         : this.param.data.ATTR_CD,
        ATTR_DIV_CD     : this.param.data.ATTR_DIV_CD,
        ATTR_DIV_NM     : this.param.data.ATTR_DIV_NM,
        USE_YN          : this.param.data.USE_YN,
        SORT_ORD        : this.param.data.SORT_ORD,
      };
    },

  },
  watch: {
    "USER_ATTR_A": async function (val) {
      this.param.data.USER_ATTR_A = val;
      this.dropB = await this.mixin_get_attr_by_attr('', val);
    },
    "USER_ATTR_B": async function (val) {
      this.param.data.USER_ATTR_B = val;
    }
  },

  methods: {

    async init() {

      this.dropItems = this.$store.getters['userStore/GE_USER_COMPANY'];
      //this.aspAllCust = await this.mixin_getAllAspData();
      this.PROC_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.REGR_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.AMDR_ID = this.$store.getters['userStore/GE_USER_ROLE'].userId;
      this.dropGroupItems = await this.mixin_user_auth_get();

      let DATA_FLAG = this.param.type;


      if (DATA_FLAG == "U") {
        this.getDetailInfo();
        this.footerYn = true;
      } else if (DATA_FLAG == "I") {
        this.footerYn = true;
      } else if (DATA_FLAG == "GU") {
        this.getDetailInfo();
        this.footerYn = true;
      }
    },

    //암호화
    common_aesEncrypt(strPassWD, strEncryptKey) {
      return gibberish.aesEncrypt(strPassWD, strEncryptKey);
    },

    //validation체크
    validate() {
      return this.$refs.form.validate();
    },

    async getDetailInfo() {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/detail";
      requestData.headers["METHOD"] = "detail";

      // sendData 세팅
      requestData.sendData["USER_ID"] = this.paramData.USER_ID;

      this.result(requestData);
    },
    getDetailInfoCallBack(response) {
      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG){
        let header = response.HEADER;
        let data = response.DATA;
        if(!this.mixin_isEmpty(data) && data.length > 0 ){
          this.detailList = data[0];
        }

        //소속 셀렉트 박스 설정
        for (let i in this.paramData.dropA) {
          this.dropA.push(this.paramData.dropA[i]);
        }
        for (let i in this.paramData.dropB) {
          this.dropB.push(this.paramData.dropB[i]);
        }
        for (let i in this.paramData.dropC) {
          this.dropC.push(this.paramData.dropC[i]);
        }
        for (let i in this.paramData.dropD) {
          this.dropD.push(this.paramData.dropD[i]);
        }
      }else{
        this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')
      }
    },

    //저장버튼 분기
    saveBtn() {
      let DATA_FLAG = this.param.type;
      if (DATA_FLAG == "U" || DATA_FLAG == "I") { //상세및 업데이트
        this.passwordProcess(DATA_FLAG);
      } else if (DATA_FLAG == "GU") { //소속등록
        this.groupAdd();
      } else if (DATA_FLAG == "ATTR_U") {
        this.attrGroupUpdate();
      } else if (DATA_FLAG == "ATTR_I") {
        this.dupleKeyChk();
      }

    },
    //소속등록
    async groupAdd() {

      if (this.paramData.USER_ATTR_A == '' || this.paramData.USER_ATTR_B == '') {
        let msg = "소속A와 소속B는 필수 입력입니다."
        this.common_alert(msg, 'error');

        return
      }

      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/registAttr";
      requestData.headers["METHOD"] = "registAttr";

      console.log(this.paramData);

      // sendData 세팅
      //requestData.sendData["ASP_NEWCUST_KEY_ATTR"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["USER_ID"] = this.detailList.USER_ID;
      requestData.sendData["USER_NM"] = this.detailList.USER_NM;
      requestData.sendData["USER_NICK"] = this.detailList.USER_NICK;
      requestData.sendData["USER_ATTR_A"] = this.paramData.USER_ATTR_A;
      requestData.sendData["USER_ATTR_B"] = this.paramData.USER_ATTR_B;
      requestData.sendData["USER_ATTR_C"] = this.paramData.USER_ATTR_C;
      requestData.sendData["USER_ATTR_D"] = this.paramData.USER_ATTR_D;
      requestData.sendData["AMDR_ID"] = this.AMDR_ID; //수정자명
      requestData.sendData["REGR_ID"] = this.REGR_ID; //등록자명
      requestData.sendData["REGR_DTTM"] = "SYSDATE";
      requestData.sendData["UPD_DTTM"] = "SYSDATE";

      this.result(requestData);

    },
    groupAddCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA;

      this.groupAddList = data[0];

      this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg              : "정상처리 되었습니다.",
        iconClass        : "svg-done-lg",
        type             : "default",
      });

      this.$emit('submit');
    },

    getDateFormat(value) {
      if (value) {
        let dateObj = value.split('/');
        let timeObj = value.split(':');
        let resultDate = dateObj[0] + dateObj[1] + dateObj[2].substr(0, 2) + timeObj[0].substr(0, -2) + timeObj[1]
        return resultDate;
      } else {
        return value;
      }

    },

    async passwordProcess(DATA_FLAG) {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["SERVICE"] = "login/password-encpt";
      requestData.headers["URL"] = "/api/login/password-encpt/process";
      requestData.headers["METHOD"] = "process";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;
      requestData.headers["passwordProcess"] = 'passwordProcess';

      this.result(requestData);
    },
    passwordProcessCallBack(response) {
      let header = response.HEADER;
      let DATA_FLAG = header.DATA_FLAG;
      let strEncryptKey = response.DATA[0].ENCRYPT_KEY;
      let strPassWD = '';
      if (strEncryptKey === "") {
        return;
      }

      //생성된 암호화 키 정보를 사용하여 ID와 password필드값을 AES 256byte 알고리즘을 이용하여 암호화한다.
      if (DATA_FLAG == "I") {
        strPassWD = this.USER_ID;
      } else {
        strPassWD = this.detailList.USER_ID;
      }
      let PWD = this.common_aesEncrypt(strPassWD, strEncryptKey);


      if (DATA_FLAG == "U") {
        this.updateDetail(PWD, DATA_FLAG);
      } else if (DATA_FLAG == "I") {
        this.userAdd(PWD, DATA_FLAG, strEncryptKey);
      } else if (DATA_FLAG == "reset") {
        this.resetPWD(strEncryptKey, PWD, DATA_FLAG);
      }

    },

    //소속 중복 체크
    async dupleKeyChk() {
      console.log(this.ATTR_DIV_CD);
      if (this.ATTR_DIV_CD.length < 2) {
        let msg = '소속코드를 2자 이상으로 입력해 주세요.';

        this.common_alert(msg, 'error');
        return
      }

      let requestData = {
        headers : {},
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/psitn-iem-manage/dplct-ceck/inqire";
      requestData.headers["SERVICE"] = "setting.agent.psitn-iem-manage.dplct-ceck";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["ASP_CUST_KEY"] = "001";
      requestData.sendData["ATTR_DIV_CD"] = this.ATTR_DIV_CD;
      requestData.sendData["ATTR_CD"] = this.paramData.ATTR_CD;

      let response = await this.common_postCall(requestData);
      this.dupleKeyChkCallback(response);
    },
    dupleKeyChkCallback(response) {
      if (Object.keys(response.DATA).length > 0) {
        let msg = '이미 사용중인 소속 코드입니다.';

        this.common_alert(msg, 'error');
        return
      }
      this.attrGroupAdd();
    },

    //소속 등록
    async attrGroupAdd() {
      let requestData = {
        headers : {},
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/psitn-iem-manage/regist";
      requestData.headers["SERVICE"] = "setting.agent.psitn-iem-manage";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["TYPE"] = "BIZ_SERVICE";
      requestData.headers["ASYNC"] = false;

      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["ATTR_CD"] = this.paramData.ATTR_CD;
      requestData.sendData["ATTR_DIV_CD"] = this.ATTR_DIV_CD;
      requestData.sendData["ATTR_DIV_NM"] = this.ATTR_DIV_NM;
      requestData.sendData["USE_YN"] = this.USE_YN;
      requestData.sendData["SORT_ORD"] = this.SORT_ORD;
      requestData.sendData["REGR_ID"] = this.REGR_ID; //등록자ID
      requestData.sendData["AMDR_ID"] = this.AMDR_ID; //수정자ID

      let attrGroupAddResponse = await this.common_postCall(requestData);
      console.log("attrGroupAddResponse");
      console.log(attrGroupAddResponse);
      this.attrGroupAddCallback(attrGroupAddResponse);
    },
    attrGroupAddCallback(response) {
      if (response.HEADER.ERROR_FLAG == false) {
        this.common_alert(response.HEADER.ERROR_MSG, "done");
        this.$emit('submit');
      }
    },

    //소속 업데이트
    attrGroupUpdate() {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/psitn-iem-manage/modify";
      requestData.headers["METHOD"] = "modify";
      requestData.headers["SERVICE"] = "setting.agent.psitn-iem-manage";


      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["ATTR_CD"] = this.paramData.ATTR_CD;
      requestData.sendData["ATTR_DIV_CD"] = this.paramData.ATTR_DIV_CD;
      requestData.sendData["ATTR_DIV_NM"] = this.paramData.ATTR_DIV_NM;
      requestData.sendData["USE_YN"] = this.paramData.USE_YN;
      requestData.sendData["SORT_ORD"] = this.paramData.SORT_ORD;
      requestData.sendData["AMDR_ID"] = this.AMDR_ID; //수정자ID

      this.result(requestData);

    },
    attrGroupUpdateCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA[0];

      if (header.ERROR_FLAG == false) {
        //alert창 설정
        let iconType = 'done';
        let msg = header.ERROR_MSG

        this.common_alert(msg, iconType);

        this.$emit('submit');
      } else {
        let iconType = 'error';
        let msg = header.ERROR_MSG

        //alert창 띄우기
        this.common_alert(msg, iconType);
      }

    },

    //비밀번호 초기화 버튼
    resetBtn() {
      this.passwordProcess("reset");
    },

    unlockBtn() {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["SERVICE"] = "setting.agent.manage.password-lock-initl";
      requestData.headers["URL"] = "/api/setting/agent/manage/password-lock-initl/process";
      requestData.headers["METHOD"] = "process";
      requestData.headers["DATA_FLAG"] = "unlock";

      //sendData 세팅

      //requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.ASP_NEWCUST_KEY_DE;
      requestData.sendData["USER_NM"] = this.detailList.USER_NM;
      requestData.sendData["USER_NICK"] = this.detailList.USER_NICK;
      requestData.sendData["USE_YN"] = this.detailList.USE_YN;
      requestData.sendData["ATRT_GROUP_NM"] = this.detailList.ATRT_GROUP_ID;
      requestData.sendData["CNNCT_IP"] = this.detailList.CNNCT_IP;
      requestData.sendData["REGR_NM"] = this.detailList.REGR_NM;
      requestData.sendData["VIEW_REG_DTTM"] = this.getDateFormat(this.detailList.VIEW_REG_DTTM);
      requestData.sendData["AMDR_NM"] = this.detailList.AMDR_NM;
      requestData.sendData["VIEW_UPD_DTTM"] = this.getDateFormat(this.detailList.VIEW_UPD_DTTM);
      requestData.sendData["HP_NO"] = this.detailList.HP_NO;
      requestData.sendData["PROC_ID"] = this.detailList.PROC_ID; //최종수정자
      requestData.sendData["BIZ_DIV"] = 'RESETPWD';
      requestData.sendData["PWD_STATUS"] = 'NORMAL';
      requestData.sendData["USER_ID"] = this.detailList.USER_ID;


      this.result(requestData);
    },
    unlockBtnCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA[0];

      //alert창 설정
      let iconType = 'done';
      let msg = "잠금 해제 되었습니다.";

      //alert창 띄우기
      this.common_alert(msg, iconType);


      this.$emit('unlock');
    },

    resetPWD(strEncryptKey, PWD, DATA_FLAG) {

      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["SERVICE"] = "setting.agent.manage.password-initl";
      requestData.headers["URL"] = "/api/setting/agent/manage/password-initl/process";
      requestData.headers["METHOD"] = "process";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;

      //sendData 세팅
      //requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.ASP_NEWCUST_KEY_DE;
      requestData.sendData["USER_NM"] = this.detailList.USER_NM;
      requestData.sendData["USER_NICK"] = this.detailList.USER_NICK;
      requestData.sendData["USE_YN"] = this.detailList.USE_YN;
      requestData.sendData["ATRT_GROUP_NM"] = this.detailList.ATRT_GROUP_ID;
      requestData.sendData["CNNCT_IP"] = this.detailList.CNNCT_IP;
      requestData.sendData["REGR_NM"] = this.detailList.REGR_NM;
      requestData.sendData["VIEW_REG_DTTM"] = this.getDateFormat(this.detailList.VIEW_REG_DTTM);
      requestData.sendData["AMDR_NM"] = this.detailList.AMDR_NM;
      requestData.sendData["VIEW_UPD_DTTM"] = this.getDateFormat(this.detailList.VIEW_UPD_DTTM);
      requestData.sendData["HP_NO"] = this.detailList.HP_NO;
      requestData.sendData["PROC_ID"] = this.detailList.PROC_ID; //최종수정자
      requestData.sendData["NEW_PWD"] = PWD;
      requestData.sendData["ENCRYPT_KEY"] = strEncryptKey;
      requestData.sendData["BIZ_DIV"] = 'RESETPWD';
      requestData.sendData["PWD_STATUS"] = 'RESET';
      requestData.sendData["USER_ID"] = this.detailList.USER_ID;

      this.result(requestData);

    },
    resetPWDCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA[0];

      let iconType = 'done';
      let msg = "비밀번호가 변경 되었습니다.";

      //alert창 띄우기
      this.common_alert(msg, iconType);


      this.$emit('reset');
    },

    chkDupleUserID() {
      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/id-dpict-ceck/inqire";
      requestData.headers["METHOD"] = "inqire";
      requestData.headers["SERVICE"] = "setting.agent.manage.id-dpict-ceck";

      // sendData 세팅
      requestData.sendData["USER_ID"] = this.USER_ID;

      this.result(requestData);

    },
    chkDupleUserIDCallBack(response) {
      let header = response.HEADER;
      let data = response.DATA[0];
      let msg = "사용할 수 없습니다! \n" + this.USER_ID + " 은(는) 이미 사용중"

      if (data) {
        this.common_alert(msg, "error");
        this.valid = false;
      } else {
        this.valid = true;
      }


    },

    userAdd(PWD, DATA_FLAG, strEncryptKey) {
      if (!this.validate()) {
        return;
      }

      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };

      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/regist";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;


      // sendData 세팅
      requestData.sendData["ASP_NEWCUST_KEY"] = this.ASP_NEWCUST_KEY;
      //requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["PWD"] = PWD;
      requestData.sendData["DATA_FLAG"] = DATA_FLAG;
      requestData.sendData["USER_ID"] = this.USER_ID;
      requestData.sendData["USER_NM"] = this.USER_NM;
      requestData.sendData["USER_NICK"] = this.USER_NICK;
      requestData.sendData["USE_YN"] = "Y";
      requestData.sendData["ATRT_GROUP_CD"] = this.ATRT_GROUP_CD;
      requestData.sendData["PROC_ID"] = this.PROC_ID; //처리자
      requestData.sendData["REGR_DEPT_CD"] = "x";
      requestData.sendData["REGR_ID"] = this.REGR_ID; //등록자
      requestData.sendData["REGR_DTTM"] = "SYSDATE";
      requestData.sendData["ENCRYPT_KEY"] = strEncryptKey;
      requestData.sendData["USER_ATTR_A"] = '027';
      requestData.sendData["USER_ATTR_B"] = '';
      requestData.sendData["REG_TYPE"] = '02';  // 수동

      this.USER_SSN = this.encryptAES_ECB(this.USER_SSN, process.env.VUE_APP_SSN_KEY);
      this.USER_ACT = this.encryptAES_ECB(this.USER_ACT, process.env.VUE_APP_SSN_KEY);

      requestData.sendData["USER_SSN"] = this.USER_SSN; //등록자
      requestData.sendData["USER_ACT"] = this.USER_ACT; //등록자

      requestData.sendData["SSN_KEY"] = process.env.VUE_APP_SSN_KEY; //등록자

      requestData.sendData["HP_NO"] = this.HP_NO; //등록자
      requestData.sendData["IPT_NO"] = this.IPT_NO; //등록자
      requestData.sendData["CEN_TYPE_CD"] = this.CEN_TYPE_CD; //등록자
      requestData.sendData["JISA"] = this.JISA; //등록자
      requestData.sendData["JISUM"] = this.JISUM; //등록자
      requestData.sendData["BRCH_CD"] = this.BRCH_CD; //등록자

      requestData.sendData["USER_BK"] = this.USER_BK; //등록자
      requestData.sendData["MEMO"] = this.MEMO; //등록자
      requestData.sendData["USER_OP"] = this.USER_OP; //등록자

      this.result(requestData);
    },
    userAddCallBack(response) {
      let iconType = 'done';
      let msg = "정상처리 되었습니다.";

      if (this.mixin_isEmpty(response) || response.HEADER.ERROR_FLAG) {
        msg = response.HEADER.ERROR_MSG;
        iconType = 'error';
      }

      //alert창 띄우기
      this.common_alert(msg, iconType);


      this.$emit('submit');

    },

    updateDetail(PWD, DATA_FLAG) {
      if (!this.validate()) {
        return;
      }

      let requestData = {
        headers : {
          SERVICE: this.initHeaders.SERVICE,
          METHOD : this.initHeaders.METHOD,
          TYPE   : this.initHeaders.TYPE,
        },
        sendData: {}
      };
      // header 세팅
      requestData.headers["URL"] = "/api/setting/agent/manage/regist";
      requestData.headers["METHOD"] = "regist";
      requestData.headers["DATA_FLAG"] = DATA_FLAG;


      // sendData 세팅
      //requestData.sendData["ASP_NEWCUST_KEY_DE"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["USER_ID"] = this.detailList.USER_ID;
      requestData.sendData["USER_NM"] = this.detailList.USER_NM;
      requestData.sendData["USER_NICK"] = this.detailList.USER_NICK;
      requestData.sendData["USE_YN"] = this.detailList.USE_YN;
      requestData.sendData["ATRT_GROUP_NM"] = this.detailList.ATRT_GROUP_ID;
      requestData.sendData["CNNCT_IP"] = this.detailList.CNNCT_IP;
      requestData.sendData["REGR_NM"] = this.detailList.REGR_NM;
      requestData.sendData["VIEW_REG_DTTM"] = this.getDateFormat(this.detailList.VIEW_REG_DTTM);
      requestData.sendData["AMDR_NM"] = this.detailList.AMDR_NM;
      requestData.sendData["VIEW_UPD_DTTM"] = this.getDateFormat(this.detailList.VIEW_UPD_DTTM);
      requestData.sendData["HP_NO"] = '';
      requestData.sendData["DATA_FLAG"] = DATA_FLAG;
      requestData.sendData["PROC_ID"] = this.PROC_ID; //최종수정자
      requestData.sendData["ASP_CUST_KEY"] = this.ASP_NEWCUST_KEY;
      requestData.sendData["PWD"] = PWD;
      requestData.sendData["AMDR_DEPT_CD"] = "x";
      requestData.sendData["AMDR_ID"] = this.AMDR_ID; //수정자명
      requestData.sendData["UPD_DTTM"] = "SYSDATE";
      requestData.sendData["MAX_CHAT_AGENT"] = this.detailList.MAX_CHAT_AGENT;


      this.result(requestData);

    },
    updateDetailCallBack(response) {
      if(!this.mixin_isEmpty(response) && !response.HEADER.ERROR_FLAG){
        this.$store.commit("alertStore/openAlertDialog", {
        alertDialogToggle: true,
        msg              : "정상처리 되었습니다.",
        iconClass        : "svg-done-lg",
        type             : "default",
      });
        this.$emit('submit');
      }else {this.common_alert(`시스템에 오류가 발생하였습니다.`, 'error')};
    },


    async result(requestData) {
      //api 호출 부분
      await api.post(requestData.headers.URL,   //api url입력
          //await axios.post('https://local.hkpalette.com:8443/'+this.URLData,{
          requestData.sendData
          , //api에서 사용될 data 입력
          {
            head: requestData.headers
          }) //api에 로그를 남기기위한 값입력 (필수) (SERVICE, METHOD, TYPE)
          .then((response) => {
            // string으로 넘어올 경우 에러임.
            if (typeof (response.data) === "string") {
              let responseData = JSON.parse(response.data.replaceAll("\n", ""));
              this.$store.commit("alertStore/openAlertDialog", {
                alertDialogToggle: true,
                msg              : responseData.HEADER.ERROR_MSG,
                iconClass        : "svg-error-lg",
                type             : "default",
              });
              return;
            }

            if (response.data.HEADER.ERROR_FLAG) {
              let msg = response.data.HEADER.ERROR_MSG
              this.common_alert(msg, "error");
              return;
            }


            if (response.data.HEADER.METHOD == "detail") {
              this.getDetailInfoCallBack(response.data);
            } else if (response.data.HEADER.passwordProcess == "passwordProcess") {
              this.passwordProcessCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "process" && response.data.HEADER.DATA_FLAG == "reset") {
              this.resetPWDCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "regist" && response.data.HEADER.DATA_FLAG == "U") {
              this.updateDetailCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "regist" && response.data.HEADER.DATA_FLAG == "I") {
              this.userAddCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "registAttr") {
              this.groupAddCallBack(response.data);
            } else if (response.data.HEADER.SERVICE == "setting.agent.manage.id-dpict-ceck") {
              this.chkDupleUserIDCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "process" && response.data.HEADER.DATA_FLAG == "unlock") {
              this.unlockBtnCallBack(response.data);
            } else if (response.data.HEADER.METHOD == "modify") {
              this.attrGroupUpdateCallBack(response.data);
            }

          }) //api 호출 성공 이후 수행
          .catch((err) => {
            alert(err);
          }) //api호출 에러 작업 수행

      //결과값 보여주기
    },

    async getList(){
      this.dropA = await this.mixin_get_attr_by_attr('****', '');

      this.USER_ATTR_A = this.param.data.USER_ATTR_A;
      this.USER_ATTR_B = this.param.data.USER_ATTR_B != '' ? this.param.data.USER_ATTR_B : '';
    },
  },
  
};
</script>

<style></style>
